/**
 * @file Copyright script.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module Scripts/Copyright
 * @requires module:Global/Functions.getCurrentYear
 */

import { getCurrentYear } from "../utils/global/functions";

const year = getCurrentYear();
const yearElement = document.querySelector("#year");

yearElement.textContent = year;
